import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <section class="flex items-center h-full p-16 ">
      <div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div class="max-w-md text-center">
          <h2 class="mb-8 font-extrabold text-9xl text-indigo-600">
            <span class="sr-only">Error</span>404
          </h2>
          <p class="text-2xl font-semibold md:text-3xl text-indigo-600">
            Sorry, we couldn't find this page.
          </p>
          <p class="mt-4 mb-8 text-indigo-600">
            But dont worry, you can find plenty of other things on our homepage.
          </p>
          <button
            className="text-sm text-left text-indigo-600 underline hover:text-indigo-900"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to HomePage
          </button>
        </div>
      </div>
    </section>
  );
}

import { createContext, useState } from "react";
import { App, Credentials } from "realm-web";
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

// Creating a Realm App Instance
const APP_ID = process.env.REACT_APP_REALM_APP_ID;
const app = new App(APP_ID);

// Creating a user context to manage and access all the user related functions
// across different components and pages.
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [checkingStatus, setCheckingStatus] = useState(true);

  // Gets a valid Realm user access token to authenticate requests
  async function getValidAccessToken() {
    // Guarantee that there's a logged in user with a valid access token
    if (!app.currentUser) {
      // If no user is logged in, log in an anonymous user. The logged in user will have a valid
      // access token.
      await fetchUser();
    } else {
      // An already logged in user's access token might be stale. To guarantee that the token is
      // valid, we refresh the user's custom data which also refreshes their access token.
      await app.currentUser.refreshCustomData();
    }

    return app.currentUser.accessToken;
  }

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://eu-west-1.aws.services.cloud.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
      // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
      // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
      // access token before sending the request.
      fetch: async (uri, options) => {
        const accessToken = await getValidAccessToken();
        options.headers.Authorization = `Bearer ${accessToken}`;
        return fetch(uri, options);
      },
    }),
    cache: new InMemoryCache(),
  });

  // Function to log in user into our App Service app using their email & password
  const emailPasswordLogin = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    setCheckingStatus(true);
    const authenticatedUser = await app.logIn(credentials);
    await authenticatedUser.refreshCustomData();
    setUser(authenticatedUser);
    setCheckingStatus(false);
    return authenticatedUser;
  };

  // Function to sign up user into our App Service app using their email & password
  const emailPasswordSignup = async (email, password) => {
    try {
      setCheckingStatus(true);
      return app.emailPasswordAuth.registerUser(email, password);
      // Since we are automatically confirming our users, we are going to log in
      // the user using the same credentials once the signup is complete.
      // setCheckingStatus(false);
      // return emailPasswordLogin(email, password);
    } catch (error) {
      setCheckingStatus(false);
      throw error;
    }
  };

  // Function to sign up user into our App Service app using their email & password
  const sendResetPasswordEmail = async (email) => {
    try {
      setCheckingStatus(true);
      const res = await app.emailPasswordAuth.sendResetPasswordEmail({ email });
      setCheckingStatus(false);
      return res;
    } catch (error) {
      setCheckingStatus(false);
      throw error;
    }
  };

  // Function to sign up user into our App Service app using their email & password
  const resetPassword = async (password, token, tokenId) => {
    try {
      setCheckingStatus(true);
      await app.emailPasswordAuth.resetPassword({
        password,
        token,
        tokenId,
      });
      setCheckingStatus(false);
    } catch (error) {
      setCheckingStatus(false);
      throw error;
    }
  };

  // Function to confirm  user into our App Service app using their token and tokenId
  const confirmUser = async (token, tokenId) => {
    try {
      setCheckingStatus(true);
      return app.emailPasswordAuth.confirmUser({
        token,
        tokenId,
      });
      // setCheckingStatus(false);
      // return emailPasswordLogin(email, password);
    } catch (error) {
      setCheckingStatus(false);
      throw error;
    }
  };

  // Function to fetch the user (if the user is already logged in) from local storage
  const fetchUser = async () => {
    if (!app.currentUser) {
      setCheckingStatus(false);
      return false;
    }
    try {
      setCheckingStatus(true);
      await app.currentUser.refreshCustomData();
      // Now, if we have a user, we are setting it to our user context
      // so that we can use it in our app across different components.
      setUser(app.currentUser);
      setCheckingStatus(false);
      return app.currentUser;
    } catch (error) {
      setCheckingStatus(false);
      throw error;
    }
  };

  // Function to logout user from our App Services app
  const logOutUser = async () => {
    if (!app.currentUser) {
      setCheckingStatus(false);
      return false;
    }
    try {
      setCheckingStatus(true);
      await app.currentUser.logOut();
      // Setting the user to null once loggedOut.
      setUser(null);
      setCheckingStatus(false);
      return true;
    } catch (error) {
      setCheckingStatus(false);
      throw error;
    }
  };

  return (
    <ApolloProvider client={client}>
      <UserContext.Provider
        value={{
          user,
          checkingStatus,
          setUser,
          fetchUser,
          emailPasswordLogin,
          emailPasswordSignup,
          sendResetPasswordEmail,
          resetPassword,
          confirmUser,
          setCheckingStatus,
          logOutUser,
        }}
      >
        {children}
      </UserContext.Provider>
    </ApolloProvider>
  );
};

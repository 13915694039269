import { Dialog, Transition } from "@headlessui/react";
import { FlagIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import Avatar from "../tree/Avatar";

export default function MemberDetails({ member, title, onCancel }) {
  function closeModal() {
    onCancel();
  }

  return (
    <>
      <Transition appear show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm p-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="flex flex-col items-center pb-4">
                    {member.img ? (
                      <img
                        className="w-24 h-24 mb-3 rounded-full shadow-lg"
                        src="/docs/images/people/profile-picture-3.jpg"
                        alt="Avatar"
                      />
                    ) : (
                      <Avatar member={member} width={96} height={96} />
                    )}
                    {member.Alive === "false" && (
                      <div className="absolute top-0 -translate-x-1 left-1/2">
                        <FlagIcon
                          className="w-8 h-8"
                          style={{ fill: "black" }}
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <h5 className="mb-1 text-xl font-medium text-gray-900 ">
                      {member.Prefix} {member.FirstName}{" "}
                      {member.Maidenname ? member.Maidenname : member.LastName}{" "}
                      {member.Suffix}
                    </h5>
                    <span className="text-sm text-gray-500 ">
                      {member.Email}
                    </span>
                    <span className="text-sm text-gray-500 capitalize ">
                      {member.Status}
                    </span>
                    {member.Bdate && (
                      <div className="flex mt-4 space-x-3 md:mt-6">
                        {new Date(member.Bdate).getFullYear()}
                        {" ---- "}
                        {member.Ddate
                          ? new Date(member.Ddate).getFullYear()
                          : "Present"}
                      </div>
                    )}
                  </div>

                  <div className="absolute top-0 right-0 flex justify-center gap-1 mt-2 mr-2">
                    <XCircleIcon
                      className="w-8 h-8 cursor-pointer"
                      aria-hidden="true"
                      onClick={closeModal}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import {
  INSERT_ONE_FAMILYMEMBER,
  UPDATE_MANY_FAMILYMEMBERS,
  UPDATE_ONE_FAMILYMEMBER,
} from "../../contexts/graphql-operations";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import moment from "moment/moment";

export default function AddMember({
  member,
  mode,
  tree_id,
  user,
  onCancel,
  onUpdate,
  getMember,
}) {
  const [insertOneFamilymember] = useMutation(INSERT_ONE_FAMILYMEMBER);
  const [updateOneFamilymember] = useMutation(UPDATE_ONE_FAMILYMEMBER);
  const [updateManyFamilymembers] = useMutation(UPDATE_MANY_FAMILYMEMBERS);
  const [showLoader, setShowLoader] = useState(false);
  const [relation, setRelation] = useState(
    !member.Mother
      ? "mother"
      : !member.Father
      ? "father"
      : !member.Spouse
      ? "partner"
      : "daughter"
  );

  function closeModal() {
    onCancel();
  }

  const [formData, setformData] = useState({
    FirstName: mode === "edit" ? member?.FirstName ?? "" : "",
    LastName: mode === "edit" ? member?.LastName ?? "" : "",
    Gender: mode === "edit" ? member?.Gender ?? "male" : "male",
    Prefix: mode === "edit" ? member?.Prefix ?? "" : "",
    Suffix: mode === "edit" ? member?.Suffix ?? "" : "",
    Status: mode === "edit" ? member?.Status ?? "" : "",
    Email: mode === "edit" ? member?.Email ?? "" : "",
    Bdate: mode === "edit" ? member?.Bdate ?? "" : "",
    Ddate: mode === "edit" ? member?.Ddate ?? "" : "",
    Alive: mode === "edit" ? member.Alive === "true" : true,
    Image: mode === "edit" ? member?.Image ?? "" : "",
    Maidenname: mode === "edit" ? member?.Maidenname ?? "" : "",
    Parent: member?.Spouse ? member?.Spouse[0]?.toString() ?? "new" : "new",
    Tree_id: tree_id,
    AddedBy: user.customData._id,
  });

  const {
    FirstName,
    LastName,
    Gender,
    Prefix,
    Suffix,
    Status,
    Email,
    Bdate,
    Ddate,
    Alive,
    Image,
    Maidenname,
    Parent,
    Tree_id,
    AddedBy,
  } = formData;

  const changeHandler = (e) => {
    setformData((prevData) => {
      return {
        ...prevData,
        [e.target.id]:
          e.target.type === "checkbox"
            ? e.target.checked === "true"
            : e.target.value,
      };
    });
  };

  const setGender = (e) => {
    setformData((prevData) => {
      return { ...prevData, Gender: e.target.value };
    });
  };

  useEffect(() => {
    if (mode === "edit") return;
    if (relation === "mother") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "female",
          };
        });
      } else {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.Maidenname,
            Maidenname: "",
            Gender: "female",
          };
        });
      }
    } else if (relation === "father") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "male",
          };
        });
      } else {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.Maidenname,
            Maidenname: "",
            Gender: "male",
          };
        });
      }
    } else if (relation === "partner") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "female",
          };
        });
      } else {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "male",
          };
        });
      }
    } else if (relation === "brother") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "male",
          };
        });
      } else {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.Maidenname ? member.Maidenname : member.LastName,
            Maidenname: "",
            Gender: "male",
          };
        });
      }
    } else if (relation === "sister") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          if (Status === "unmarried") {
            return {
              ...prevData,
              Maidenname: "",
              LastName: member.LastName,
              Gender: "female",
            };
          } else {
            return {
              ...prevData,
              Maidenname: member.LastName,
              LastName: "",
              Gender: "female",
            };
          }
        });
      } else {
        setformData((prevData) => {
          if (Status === "unmarried") {
            return {
              ...prevData,
              Maidenname: "",
              LastName: member.Maidenname,
              Gender: "female",
            };
          } else {
            return {
              ...prevData,
              Maidenname: member.Maidenname
                ? member.Maidenname
                : member.LastName,
              LastName: "",
              Gender: "female",
            };
          }
        });
      }
    } else if (relation === "son") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "male",
          };
        });
      } else {
        setformData((prevData) => {
          return {
            ...prevData,
            LastName: member.LastName,
            Maidenname: "",
            Gender: "male",
          };
        });
      }
    } else if (relation === "daughter") {
      if (member.Gender === "male") {
        setformData((prevData) => {
          if (Status === "unmarried") {
            return {
              ...prevData,
              Maidenname: "",
              LastName: member.LastName,
              Gender: "female",
            };
          } else {
            return {
              ...prevData,
              Maidenname: member.LastName,
              LastName: "",
              Gender: "female",
            };
          }
        });
      } else {
        setformData((prevData) => {
          if (Status === "unmarried") {
            return {
              ...prevData,
              LastName: member.LastName,
              Maidenname: "",
              Gender: "female",
            };
          } else {
            return {
              ...prevData,
              Maidenname: member.LastName,
              LastName: "",
              Gender: "female",
            };
          }
        });
      }
    }
  }, [relation, Status]);

  const addMember = async (e) => {
    e.preventDefault();
    try {
      setShowLoader(true);
      if (mode === "edit") {
        const data = await updateOneFamilymember({
          variables: {
            query: {
              _id: member._id.toString(),
            },
            set: {
              FirstName,
              LastName,
              Gender,
              Prefix,
              Suffix,
              Status,
              Email,
              Bdate: new Date(Bdate),
              Ddate: new Date(Ddate),
              Alive,
              Image,
              Maidenname,
              Tree_id: {
                link: Tree_id,
              },
              AddedBy: {
                link: AddedBy,
              },
            },
          },
        });
      } else {
        if (relation === "son" || relation === "daughter") {
          if (!Parent) {
            setShowLoader(false);
            return toast.error(
              `Please choose ${member.Gender === "male" ? "mother" : "father"}`
            );
          }
        }
        const query = {
          FirstName,
          LastName,
          Gender,
          Prefix,
          Suffix,
          Status,
          Email,
          Bdate: new Date(Bdate),
          Ddate: new Date(Ddate),
          Alive,
          Image,
          Maidenname,
          Tree_id: {
            link: Tree_id,
          },
          AddedBy: {
            link: AddedBy,
          },
        };

        if (relation === "mother") {
          // add current member as child in tobe mother
          query.Child = { link: member._id.toString() };
          // if current memeber has father then find children without mother and add those in tobe mother
          if (member.Father) {
            query.Spouse = { link: member.Father.toString() };
            const memberFather = getMember(member.Father.toString());
            if (memberFather.Child) {
              const childrenWithoutMother = memberFather.Child.filter(
                (child) => !getMember(child.toString()).Mother
              );
              if (childrenWithoutMother.length) {
                query.Child = {
                  link: childrenWithoutMother.map((child) => {
                    return child.toString();
                  }),
                };
              }
            }
          }
          // add mother
          const data = await insertOneFamilymember({
            variables: {
              query,
            },
          });
          console.log("data", data);
          if (data.data?.insertOneFamilymember) {
            const addedMemberId = data.data?.insertOneFamilymember._id;
            // update newly added mother Id in current member
            try {
              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: member._id.toString(),
                  },
                  set: {
                    Mother: { link: addedMemberId },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating mother in ${member.FirstName}`
              );
            }
            // if current memeber has father then add mother as his spouse
            if (member.Father) {
              try {
                const memberFather = getMember(member.Father.toString());
                const otherSpouse = memberFather.Spouse?.map((spouse) =>
                  spouse.toString()
                );
                await updateOneFamilymember({
                  variables: {
                    query: {
                      _id: member.Father._id.toString(),
                    },
                    set: {
                      Spouse: {
                        link: otherSpouse
                          ? [...otherSpouse, addedMemberId]
                          : [addedMemberId],
                      },
                    },
                  },
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.error ??
                    "Something went wrong when updating spouse in father."
                );
              }
              // update newly added mother Id in all siblings without mother
              try {
                await updateManyFamilymembers({
                  variables: {
                    query: {
                      Father: { _id: member.Father.toString() },
                      Mother_exists: false,
                    },
                    set: {
                      Mother: { link: addedMemberId },
                    },
                  },
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.error ??
                    "Something went wrong when updating Mother in siblings."
                );
              }
            }
          }
        } else if (relation === "father") {
          // add current member as child in tobe father
          query.Child = { link: member._id.toString() };
          // if current memeber has mother then find children without father and add those in tobe father
          if (member.Mother) {
            query.Spouse = { link: member.Mother.toString() };
            const memberMother = getMember(member.Mother.toString());
            if (memberMother.Child) {
              const childrenWithoutFather = memberMother.Child.filter(
                (child) => !getMember(child.toString()).Father
              );
              if (childrenWithoutFather.length) {
                query.Child = {
                  link: childrenWithoutFather.map((child) => {
                    return child.toString();
                  }),
                };
              }
            }
          }
          // add father
          const data = await insertOneFamilymember({
            variables: {
              query,
            },
          });
          console.log("data", data);
          if (data.data?.insertOneFamilymember) {
            const addedMemberId = data.data?.insertOneFamilymember._id;
            // update newly added father Id in current member
            try {
              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: member._id.toString(),
                  },
                  set: {
                    Father: { link: addedMemberId },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating father in ${member.FirstName}`
              );
            }
            // if current memeber has mother then add father as his spouse
            if (member.Mother) {
              try {
                const memberMother = getMember(member.Mother.toString());
                const otherSpouse = memberMother.Spouse?.map((spouse) =>
                  spouse.toString()
                );
                await updateOneFamilymember({
                  variables: {
                    query: {
                      _id: member.Mother.toString(),
                    },
                    set: {
                      Spouse: {
                        link: otherSpouse
                          ? [...otherSpouse, addedMemberId]
                          : [addedMemberId],
                      },
                    },
                  },
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.error ??
                    "Something went wrong when updating spouse in mother."
                );
              }
              // update newly added father Id in all siblings without father
              try {
                await updateManyFamilymembers({
                  variables: {
                    query: {
                      Mother: { _id: member.Mother.toString() },
                      Father_exists: false,
                    },
                    set: {
                      Father: { link: addedMemberId },
                    },
                  },
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.errors?.map((err) => err.message)?.toString() ??
                    "Something went wrong when updating Father in siblings."
                );
              }
            }
          }
        } else if (relation === "partner") {
          //if current person has children and this is the first partner going to add then
          // add the child of current person to the first partner.
          query.Spouse = { link: member._id.toString() };
          if (member.Child?.length && !member.Spouse?.length) {
            const childrenWithSingleParent = member.Child.filter((child) => {
              const Child = getMember(child.toString());
              return Child.Father?.toString() === member._id.toString()
                ? !Child.Mother
                : !Child.Father;
            });
            query.Child = {
              link: childrenWithSingleParent.map((child) => {
                return child.toString();
              }),
            };
          }
          // add partner
          const data = await insertOneFamilymember({
            variables: {
              query,
            },
          });
          console.log("data", data);
          if (data.data?.insertOneFamilymember) {
            const addedMemberId = data.data?.insertOneFamilymember._id;
            // update spouse in current member
            try {
              const otherSpouse = member.Spouse?.map((spouse) =>
                spouse.toString()
              );
              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: member._id.toString(),
                  },
                  set: {
                    Spouse: {
                      link: otherSpouse
                        ? [...otherSpouse, addedMemberId]
                        : [addedMemberId],
                    },
                  },
                },
              });
            } catch (error) {
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating spouse in ${member.FirstName}`
              );
            }
            // update newly added spouse Id as mother/father in all siblings without father/mother
            if (member.Child?.length) {
              let childrenVars = {
                query: {
                  Mother: { _id: member._id.toString() },
                  Father_exists: false,
                },
                set: {
                  Father: { link: addedMemberId },
                },
              };
              const firstChild = getMember(member.Child[0]?.toString());
              if (firstChild.Father?.toString() === member._id.toString()) {
                childrenVars = {
                  query: {
                    Father: { _id: member._id.toString() },
                    Mother_exists: false,
                  },
                  set: {
                    Mother: { link: addedMemberId },
                  },
                };
              }
              try {
                await updateManyFamilymembers({
                  variables: childrenVars,
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.errors?.map((err) => err.message)?.toString() ??
                    "Something went wrong when updating Father in siblings."
                );
              }
            }
          }
        } else if (relation === "brother" || relation === "sister") {
          if (member.Father) {
            query.Father = { link: member.Father.toString() };
          } else {
            query.Father = {
              create: {
                FirstName: `Father of ${member.FirstName}`,
                LastName: member.LastName,
                Gender: "male",
                Prefix: "",
                Suffix: "",
                Status: "",
                Email: "",
                Alive: true,
                Image: "",
                Child: { link: member._id.toString() },
                Maidenname: "",
                Tree_id: {
                  link: Tree_id,
                },
                AddedBy: {
                  link: AddedBy,
                },
              },
            };
          }
          if (member.Mother) {
            query.Mother = { link: member.Mother.toString() };
          } else {
            query.Mother = {
              create: {
                FirstName: `Mother of ${member.FirstName}`,
                LastName: member.LastName,
                Gender: "female",
                Prefix: "",
                Suffix: "",
                Status: "",
                Email: "",
                Alive: true,
                Image: "",
                Child: { link: member._id.toString() },
                Maidenname: "",
                Tree_id: {
                  link: Tree_id,
                },
                AddedBy: {
                  link: AddedBy,
                },
              },
            };
          }
          const data = await insertOneFamilymember({
            variables: {
              query,
            },
          });
          console.log("data", data);
          if (data.data?.insertOneFamilymember) {
            const addedMemberId = data.data?.insertOneFamilymember._id;
            const addedMemberMotherId =
              data.data?.insertOneFamilymember.Mother._id;
            const addedMemberFatherId =
              data.data?.insertOneFamilymember.Father._id;

            const otherChildrenInMother =
              data.data?.insertOneFamilymember.Mother.Child?.map(
                (child) => child._id
              );
            const otherSpouseInMother =
              data.data?.insertOneFamilymember.Mother.Spouse?.map(
                (spouse) => spouse._id
              );
            try {
              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: addedMemberMotherId,
                  },
                  set: {
                    Child: {
                      link: otherChildrenInMother
                        ? [...otherChildrenInMother, addedMemberId]
                        : [addedMemberId],
                    },
                    Spouse: {
                      link: otherSpouseInMother
                        ? [
                            ...otherSpouseInMother.filter(
                              (id) => id !== addedMemberFatherId
                            ),
                            addedMemberFatherId,
                          ]
                        : [addedMemberFatherId],
                    },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating child in ${member.FirstName}'s Mother`
              );
            }

            const otherChildrenInFather =
              data.data?.insertOneFamilymember.Father.Child?.map(
                (child) => child._id
              );
            const otherSpouseInFather =
              data.data?.insertOneFamilymember.Father.Spouse?.map(
                (spouse) => spouse._id
              );
            try {
              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: addedMemberFatherId,
                  },
                  set: {
                    Child: {
                      link: otherChildrenInFather
                        ? [...otherChildrenInFather, addedMemberId]
                        : [addedMemberId],
                    },
                    Spouse: {
                      link: otherSpouseInFather
                        ? [
                            ...otherSpouseInFather.filter(
                              (id) => id !== addedMemberMotherId
                            ),
                            addedMemberMotherId,
                          ]
                        : [addedMemberMotherId],
                    },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating child in ${member.FirstName}'s Father`
              );
            }

            if (!member.Father || !member.Mother) {
              const set = {};
              if (!member.Father) set.Father = { link: addedMemberFatherId };
              if (!member.Mother) set.Mother = { link: addedMemberMotherId };
              try {
                await updateOneFamilymember({
                  variables: {
                    query: {
                      _id: member._id.toString(),
                    },
                    set: set,
                  },
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.error ??
                    `Something went wrong when updating Mother/Father in ${member.FirstName}`
                );
              }
            }
          }
        } else if (relation === "son" || relation === "daughter") {
          if (Parent === "new") {
            if (member.Gender === "male") {
              query.Father = { link: member._id.toString() };
              query.Mother = {
                create: {
                  FirstName: `Mother of ${FirstName}`,
                  LastName: LastName,
                  Gender: "female",
                  Prefix: "",
                  Suffix: "",
                  Status: "",
                  Email: "",
                  Alive: true,
                  Image: "",
                  Spouse: { link: member._id.toString() },
                  Maidenname: "",
                  Tree_id: {
                    link: Tree_id,
                  },
                  AddedBy: {
                    link: AddedBy,
                  },
                },
              };
            } else if (member.Gender === "female") {
              query.Mother = { link: member._id.toString() };
              query.Father = {
                create: {
                  FirstName: `Father of ${FirstName}`,
                  LastName: LastName,
                  Gender: "male",
                  Prefix: "",
                  Suffix: "",
                  Status: "",
                  Email: "",
                  Alive: true,
                  Image: "",
                  Spouse: { link: member._id.toString() },
                  Maidenname: "",
                  Tree_id: {
                    link: Tree_id,
                  },
                  AddedBy: {
                    link: AddedBy,
                  },
                },
              };
            }
          } else if (Parent === "no") {
            if (member.Gender === "male") {
              query.Father = { link: member._id.toString() };
            } else {
              query.Mother = { link: member._id.toString() };
            }
          } else {
            if (member.Gender === "male") {
              query.Mother = { link: Parent };
              query.Father = { link: member._id.toString() };
            } else {
              query.Father = { link: Parent };
              query.Mother = { link: member._id.toString() };
            }
          }
          const data = await insertOneFamilymember({
            variables: {
              query,
            },
          });
          console.log("data", data);
          if (data.data?.insertOneFamilymember) {
            const addedMemberId = data.data?.insertOneFamilymember._id;
            const addedMemberMotherId =
              data.data?.insertOneFamilymember.Mother._id;
            const addedMemberFatherId =
              data.data?.insertOneFamilymember.Father._id;
            if (Parent === "new") {
              const otherSpouse = member.Spouse?.map((spouse) =>
                spouse.toString()
              );
              try {
                await updateOneFamilymember({
                  variables: {
                    query: {
                      _id: member._id.toString(),
                    },
                    set: {
                      Spouse:
                        member.Gender === "male"
                          ? {
                              link: otherSpouse
                                ? [...otherSpouse, addedMemberMotherId]
                                : [addedMemberMotherId],
                            }
                          : {
                              link: otherSpouse
                                ? [...otherSpouse, addedMemberFatherId]
                                : [addedMemberFatherId],
                            },
                    },
                  },
                });
              } catch (error) {
                console.log(error);
                setShowLoader(false);
                toast.error(
                  error?.error ??
                    `Something went wrong when updating Spouse in ${member.FirstName}`
                );
              }
            }
            try {
              // getting other children from mother to keep all children in mother
              const otherChildrenInMother =
                data.data?.insertOneFamilymember.Mother.Child?.map(
                  (child) => child._id
                );
              // other children in Father without mother
              const otherChildreninFatherWoMother =
                data.data?.insertOneFamilymember.Father.Child?.filter(
                  (child) => !getMember(child._id).Mother
                );
              const otherChildreninFather = otherChildreninFatherWoMother.map(
                (child) => child._id
              );
              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: addedMemberMotherId,
                  },
                  set: {
                    Child: {
                      link: otherChildrenInMother
                        ? [
                            ...otherChildrenInMother,
                            ...otherChildreninFather,
                            addedMemberId,
                          ]
                        : [...otherChildreninFather, addedMemberId],
                    },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating child in ${member.FirstName}'s Mother`
              );
            }
            try {
              // getting other children from father to keep all children in father
              const otherChildreninFather =
                data.data?.insertOneFamilymember.Father.Child?.map(
                  (child) => child._id
                );
              // other children in Mother without father
              const otherChildrenInMotherWoFather =
                data.data?.insertOneFamilymember.Mother.Child?.filter(
                  (child) => !getMember(child._id).Father
                );
              const otherChildrenInMother = otherChildrenInMotherWoFather.map(
                (child) => child._id
              );

              await updateOneFamilymember({
                variables: {
                  query: {
                    _id: addedMemberFatherId,
                  },
                  set: {
                    Child: {
                      link: otherChildreninFather
                        ? [
                            ...otherChildreninFather,
                            ...otherChildrenInMother,
                            addedMemberId,
                          ]
                        : [...otherChildrenInMother, addedMemberId],
                    },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating child in ${member.FirstName}'s Father`
              );
            }

            try {
              // Update Mother which has same Father but no mother
              await updateManyFamilymembers({
                variables: {
                  query: {
                    Father: { _id: addedMemberFatherId },
                    Mother_exists: false,
                  },
                  set: {
                    Mother: { link: addedMemberMotherId },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating father in ${member.FirstName}'s Sibling`
              );
            }

            try {
              // Update Father which has same Mother but no Father
              await updateManyFamilymembers({
                variables: {
                  query: {
                    Mother: { _id: addedMemberMotherId },
                    Father_exists: false,
                  },
                  set: {
                    Father: { link: addedMemberFatherId },
                  },
                },
              });
            } catch (error) {
              console.log(error);
              setShowLoader(false);
              toast.error(
                error?.error ??
                  `Something went wrong when updating father in ${member.FirstName}'s Sibling`
              );
            }
          }
        }
      }
      onUpdate();
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(
        error?.error ?? "Something went wrong with add / update member."
      );
    }
  };

  if (showLoader) {
    return <Spinner />;
  }

  return (
    <>
      <Transition appear show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {mode === "edit"
                      ? "Edit Member"
                      : `Add Family Member - ${member.FirstName} ${member.LastName}`}
                  </Dialog.Title>
                  <form onSubmit={addMember}>
                    {mode === "add" && (
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="relation"
                            name="relation"
                            onChange={(e) => {
                              setRelation(e.target.value);
                            }}
                            value={relation}
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            {!member.Mother && (
                              <option value="mother">Add Mother</option>
                            )}
                            {!member.Father && (
                              <option value="father">Add Father</option>
                            )}
                            {!member.Spouse && (
                              <option value="partner">Add Partner</option>
                            )}
                            <option value="daughter">Add Daughter</option>
                            <option value="son">Add Son</option>
                            <option value="brother">Add Brother</option>
                            <option value="sister">Add Sister</option>
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="mt-4">
                      <div className="flex flex-row items-start">
                        <div className="flex items-center mr-4">
                          <input
                            checked={Gender === "male"}
                            id="inline-radio"
                            type="radio"
                            value="male"
                            name="gender"
                            onChange={setGender}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          />
                          <label
                            htmlFor="inline-radio"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Male
                          </label>
                        </div>
                        <div className="flex items-center mr-4">
                          <input
                            checked={Gender === "female"}
                            id="inline-2-radio"
                            type="radio"
                            value="female"
                            name="gender"
                            onChange={setGender}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          />
                          <label
                            htmlFor="inline-2-radio"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Female
                          </label>
                        </div>
                        <div className="flex items-center mr-4">
                          <input
                            checked={Gender === "unknown"}
                            id="inline-checked-radio"
                            type="radio"
                            value="unknown"
                            name="gender"
                            onChange={setGender}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          />
                          <label
                            htmlFor="inline-checked-radio"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Unknown
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-x-4 md:grid-cols-2 ">
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="Status"
                            name="Status"
                            onChange={changeHandler}
                            value={Status}
                            required
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            <option>Choose status</option>
                            <option value="married">Married</option>
                            <option value="unmarried">Unmarried</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow / Widower</option>
                            <option value="separated">Separated</option>
                            <option value="engaged">Engaged</option>
                            <option value="partners">Partners</option>
                            <option value="friends">Friends</option>
                            <option value="unknown">Unknown</option>
                            <option value="none">None</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="Prefix"
                            name="Prefix"
                            onChange={changeHandler}
                            value={Prefix}
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            <option value="">Choose prefix</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Prof.">Prof.</option>
                            <option value="Rev">Rev</option>
                            <option value="Sir">Sir</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4 ">
                        <div className="flex flex-col items-start">
                          <input
                            type="text"
                            name="FirstName"
                            id="FirstName"
                            value={FirstName}
                            onInput={changeHandler}
                            required
                            placeholder="First Name"
                            className="block w-full p-1.5 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                      <div className="mt-4 ">
                        <div className="flex flex-col items-start">
                          <input
                            type="text"
                            name="LastName"
                            id="LastName"
                            value={LastName}
                            onInput={changeHandler}
                            required
                            placeholder="Last Name"
                            className="block w-full p-1.5 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                      {Gender !== "male" && Status !== "unmarried" && (
                        <div className="mt-4 ">
                          <div className="flex flex-col items-start">
                            <input
                              type="text"
                              name="Maidenname"
                              id="Maidenname"
                              value={Maidenname}
                              onInput={changeHandler}
                              placeholder="Maiden Name"
                              className="block w-full p-1.5 border rounded border-grey-light"
                            />
                          </div>
                        </div>
                      )}
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="Suffix"
                            name="Suffix"
                            onChange={changeHandler}
                            value={Suffix}
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            <option value="">Choose suffix</option>
                            <option value="I">I</option>
                            <option value="II">II</option>
                            <option value="III">III</option>
                            <option value="Sr">Sr</option>
                            <option value="Jr">Jr</option>
                            <option value="Esq.">Esq.</option>
                          </select>
                        </div>
                      </div>
                      {mode !== "edit" &&
                        (relation === "son" || relation === "daughter") && (
                          <div className="mt-4">
                            <div className="flex flex-col items-start">
                              <select
                                id="Parent"
                                name="Parent"
                                onChange={changeHandler}
                                value={Parent}
                                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                              >
                                {/* <option>
                                  {member.Gender === "male"
                                    ? "Choose mother"
                                    : "Choose father"}
                                </option> */}
                                {member.Spouse &&
                                  member.Spouse.map((spouse) => {
                                    return (
                                      <option
                                        key={spouse.toString()}
                                        value={spouse.toString()}
                                      >
                                        {member.Gender === "male"
                                          ? "Mother - "
                                          : "Father - "}
                                        {getMember(spouse.toString()).FirstName}{" "}
                                        {getMember(spouse.toString()).LastName}
                                      </option>
                                    );
                                  })}
                                {!member.Spouse && (
                                  <option value="new">
                                    New{" "}
                                    {member.Gender === "male"
                                      ? "Mother"
                                      : "Father"}
                                  </option>
                                )}
                                {/* <option value="no">
                                  No{" "}
                                  {member.Gender === "male"
                                    ? "Mother"
                                    : "Father"}
                                </option> */}
                              </select>
                            </div>
                          </div>
                        )}
                      <div className="mt-4 ">
                        <div className="flex flex-col items-start">
                          <input
                            type="email"
                            name="Email"
                            id="Email"
                            value={Email}
                            onInput={changeHandler}
                            placeholder="Email address"
                            className="block w-full p-1.5 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-4 gap-x-4">
                      <div className="mt-4 ">
                        <div className="flex flex-row items-start">
                          <h3 className="mr-4 font-semibold leading-8 text-gray-900 ">
                            Birthdate:
                          </h3>
                          <input
                            type="date"
                            name="Bdate"
                            id="Bdate"
                            value={moment(Bdate).format("YYYY-MM-DD")}
                            max={moment().format("YYYY-MM-DD")}
                            onInput={changeHandler}
                            placeholder="Maiden Name"
                            className="block w-full p-1 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                      <div className="mt-4 ">
                        <div className="flex items-center">
                          <input
                            checked={Alive}
                            id="Alive"
                            name="Alive"
                            type="checkbox"
                            onChange={changeHandler}
                            className="w-4 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded "
                          />
                          <label
                            htmlFor="Alive"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Alive
                          </label>
                        </div>
                      </div>
                      {!Alive && (
                        <div className="mt-4 ">
                          <div className="flex flex-row items-start">
                            <h3 className="mr-4 font-semibold leading-8 text-gray-900 whitespace-nowrap">
                              Date of Death:
                            </h3>
                            <input
                              type="date"
                              name="Ddate"
                              id="Ddate"
                              value={moment(Ddate).format("YYYY-MM-DD")}
                              max={moment().format("YYYY-MM-DD")}
                              onInput={changeHandler}
                              placeholder="Maiden Name"
                              className="block w-full p-1 border rounded border-grey-light"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="w-full py-3 my-1 text-center text-white bg-indigo-600 rounded px-7 hover:bg-indigo-900 focus:outline-none"
                    >
                      {mode === "edit" ? "Update Member" : "Add Member"}
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  DELETE_ONE_PERMISSION,
  FIND_PERMISSIONS,
  INSERT_ONE_PERMISSION,
  UPDATE_ONE_PERMISSION,
} from "../../contexts/graphql-operations";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import Spinner from "../Spinner";
import { toast } from "react-toastify";

export default function ShareDialog({
  user,
  tree,
  title,
  onConfirm,
  onCancel,
}) {
  const { _id } = tree;
  const [emailAddress, setEmailAddress] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [insertOnePermission] = useMutation(INSERT_ONE_PERMISSION);
  const [deleteOnePermission] = useMutation(DELETE_ONE_PERMISSION);
  const [updateOnePermission] = useMutation(UPDATE_ONE_PERMISSION);
  const {
    loading,
    refetch,
    data: permissionData,
  } = useQuery(FIND_PERMISSIONS, {
    variables: {
      query: {
        tree_id: { _id },
      },
    },
  });

  const closeModal = () => {
    onCancel();
  };

  const removePermission = async (id) => {
    setShowLoader(true);
    try {
      const data = await deleteOnePermission({
        variables: {
          query: {
            _id: id,
          },
        },
      });
      console.log("data", data);
      setShowLoader(false);
      refetchPermissions();
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(
        error.toString() ?? "Something went wrong when removing permission"
      );
    }
  };

  const updatePermission = async (id, permission, value) => {
    setShowLoader(true);
    const set =
      permission === "can_edit"
        ? { can_edit: value }
        : permission === "can_add"
        ? { can_add: value }
        : { can_delete: value };
    try {
      const data = await updateOnePermission({
        variables: {
          query: {
            _id: id,
          },
          set: set,
        },
      });
      console.log("data", data);
      setShowLoader(false);
      refetchPermissions();
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(
        error.toString() ?? "Something went wrong when removing permission"
      );
    }
  };

  const addPermission = async (e) => {
    e.preventDefault();
    if (permissionData) {
      const isExist = permissionData.permissions.find(
        (permission) => permission.user_email === emailAddress
      );
      if (isExist) {
        return toast.error(`You have already shared with ${emailAddress}`);
      }
    }
    if (user.customData.user_email === emailAddress) {
      return toast.error(`You cannot share with yourself.`);
    }
    setShowLoader(true);
    try {
      const data = await insertOnePermission({
        variables: {
          query: {
            user_email: emailAddress,
            tree_id: { link: _id },
            can_edit: false,
            can_delete: false,
            can_add: false,
          },
        },
      });
      console.log("data", data);
      setShowLoader(false);
      setEmailAddress("");
      refetchPermissions();
      let fd = new FormData();
      fd.set("first_name", user.customData.first_name);
      fd.set("last_name", user.customData.last_name);
      fd.set("email", emailAddress);

      fetch("https://familytree.livecards.in/mail/index.php", {
        method: "post",
        body: fd,
      });
      // .then((r) => r.text())
      // .then(console.log)
      // .catch(console.warn);
      // if (data.data?.insertOneTree?._id) {
      //   navigate(`/tree/${data.data?.insertOneTree?._id}`);
      // }
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(
        error?.error ?? "Something went wrong when adding permission"
      );
    }
  };

  const refetchPermissions = async () => {
    setShowLoader(true);
    await refetch();
    setShowLoader(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {showLoader && <Spinner />}
      <Transition appear show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="mt-4">
                      <form className="mt-4" onSubmit={addPermission}>
                        <div className="flex flex-col items-center mt-1 text-sm sm:flex-row sm:space-y-0 sm:space-x-4">
                          <div className="w-full sm:mb-2">
                            <label htmlFor="input1">
                              <input
                                id="input1"
                                value={emailAddress}
                                className="w-full px-5 py-3 mt-1 border-2 border-indigo-300 outline-none rounded-2xl placeholder:text-gray-400 invalid:text-pink-700 invalid:focus:ring-pink-700 invalid:focus:border-pink-700 peer "
                                type="email"
                                onInput={(e) => {
                                  setEmailAddress(e.currentTarget.value);
                                }}
                                require="true"
                                placeholder="Enter email address"
                              />
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="w-full px-8 py-3 mt-3 text-sm font-medium text-center text-gray-100 bg-indigo-500 cursor-pointer rounded-2xl sm:w-min hover:bg-indigo-700 hover:text-gray-50 sm:mb-0"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="mt-4">
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 ">
                          <thead className="text-xs text-white uppercase bg-indigo-500 ">
                            <tr>
                              <th scope="col" className="p-3">
                                Can View
                              </th>
                              <th scope="col" className="p-3 whitespace-nowrap">
                                Can edit?
                              </th>
                              <th scope="col" className="p-3 whitespace-nowrap">
                                can Add?
                              </th>
                              <th scope="col" className="p-3 whitespace-nowrap">
                                can Delete?
                              </th>

                              <th scope="col" className="p-3">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {permissionData?.permissions.length ? (
                              permissionData.permissions.map(
                                (permission, index) => {
                                  return (
                                    <tr
                                      className="bg-white border-b hover:bg-gray-50 "
                                      key={`permission-${index}`}
                                    >
                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                                      >
                                        {permission.user_email}
                                      </th>
                                      <td className="w-4 px-6 py-4">
                                        <div className="flex items-center">
                                          <input
                                            id={`checkbox-edit-${index}`}
                                            type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            checked={permission.can_edit}
                                            onChange={(e) => {
                                              updatePermission(
                                                permission._id,
                                                "can_edit",
                                                e.currentTarget.checked
                                              );
                                            }}
                                          />
                                          <label
                                            htmlFor={`checkbox-edit-${index}`}
                                            className="sr-only"
                                          >
                                            can user edit?
                                          </label>
                                        </div>
                                      </td>
                                      <td className="w-4 px-6 py-4">
                                        <div className="flex items-center">
                                          <input
                                            id={`checkbox-add-${index}`}
                                            type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            checked={permission.can_add}
                                            onChange={(e) => {
                                              updatePermission(
                                                permission._id,
                                                "can_add",
                                                e.currentTarget.checked
                                              );
                                            }}
                                          />
                                          <label
                                            htmlFor={`checkbox-add-${index}`}
                                            className="sr-only"
                                          >
                                            can user add?
                                          </label>
                                        </div>
                                      </td>
                                      <td className="w-4 px-6 py-4">
                                        <div className="flex items-center">
                                          <input
                                            id={`checkbox-delete-${index}`}
                                            type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                            checked={permission.can_delete}
                                            onChange={(e) => {
                                              updatePermission(
                                                permission._id,
                                                "can_delete",
                                                e.currentTarget.checked
                                              );
                                            }}
                                          />
                                          <label
                                            htmlFor={`checkbox-delete-${index}`}
                                            className="sr-only"
                                          >
                                            can user delete?
                                          </label>
                                        </div>
                                      </td>
                                      <td className="flex items-center p-3 space-x-3">
                                        <button
                                          className="font-medium text-red-600 hover:underline"
                                          onClick={() => {
                                            removePermission(permission._id);
                                          }}
                                        >
                                          Remove
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <td colSpan={5} className="p-3 text-center ">
                                You haven't share yet.
                              </td>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="absolute top-0 right-0 flex justify-center gap-1 mt-2 mr-2">
                    <XCircleIcon
                      className="w-8 h-8 cursor-pointer"
                      aria-hidden="true"
                      onClick={closeModal}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

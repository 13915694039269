import { Popover, Transition } from "@headlessui/react";
import Avatar from "./Avatar";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  UserMinusIcon,
  UserPlusIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import AddMember from "../Dialog/AddMember";
import Spinner from "../Spinner";
import Confirm from "../Dialog/Confirm";
import { toast } from "react-toastify";
import {
  DELETE_ONE_FAMILYMEMBER,
  FIND_FAMILYMEMBER,
  UPDATE_MANY_FAMILYMEMBERS,
  UPDATE_ONE_FAMILYMEMBER,
} from "../../contexts/graphql-operations";
import { useMutation, useQuery } from "@apollo/client";
import Line from "./Line";
import ridgeIcon from "../../assets/images/ridgeIcon.svg";
import useWindowSize from "../../hooks/useWindowSize";
import MemberDetails from "../Dialog/MemberDetails";
// const callsToAction = [
//   { name: "Watch demo", href: "#", icon: PlayCircleIcon },
//   { name: "Contact sales", href: "#", icon: PhoneIcon },
// ];

export default function Member({
  refetch,
  member,
  user,
  tree_id,
  memberPosition,
  lines,
  showRidgeIcon,
  showTree,
  showTooltip,
  makeCenter,
  getMember,
  permissionData,
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [updateOneFamilymember] = useMutation(UPDATE_ONE_FAMILYMEMBER);
  const [deleteOneFamilymember] = useMutation(DELETE_ONE_FAMILYMEMBER);
  const [mode, setMode] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { refetch: refetchMember } = useQuery(FIND_FAMILYMEMBER, {
    variables: { skip: true },
  });
  const [showTip, setShowTip] = useState(showTooltip);
  const windowSize = useWindowSize();
  const [updateManyFamilymembers] = useMutation(UPDATE_MANY_FAMILYMEMBERS);

  const showMyTree = (e) => {
    showTree(e.currentTarget.parentElement);
  };

  const onUpdate = () => {
    setShowConfirm(false);
    refetch();
  };
  const confirmDelete = async () => {
    setShowDeleteConfirm(false);
    setShowLoader(true);
    if (member.Mother) {
      try {
        const filteredChildren = getMember(
          member.Mother.toString()
        )?.Child?.filter((child) => child.toString() !== member._id.toString());
        console.log("filteredChildren in mother", filteredChildren);
        if (filteredChildren) {
          await updateOneFamilymember({
            variables: {
              query: {
                _id: member.Mother.toString(),
              },
              set: {
                Child: {
                  link: filteredChildren.map((child) => child.toString()),
                },
              },
            },
          });
        }
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        toast.error(
          error?.error ?? `Something went wrong when updating child in Mother`
        );
      }
    }
    if (member.Father) {
      try {
        const filteredChildren = getMember(
          member.Father.toString()
        )?.Child?.filter((child) => child.toString() !== member._id.toString());
        console.log("filteredChildren in Father", filteredChildren);
        if (filteredChildren) {
          await updateOneFamilymember({
            variables: {
              query: {
                _id: member.Father.toString(),
              },
              set: {
                Child: {
                  link: filteredChildren.map((child) => child.toString()),
                },
              },
            },
          });
        }
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        toast.error(
          error?.error ?? `Something went wrong when updating child in Father`
        );
      }
    }
    if (member.Child) {
      try {
        let updateChildVars = {
          query: {
            Father: { _id: member._id.toString() },
          },
          set: {
            Father_unset: true,
          },
        };
        if (member.Gender === "female") {
          updateChildVars = {
            query: {
              Mother: { _id: member._id.toString() },
            },
            set: {
              Mother_unset: true,
            },
          };
        }
        await updateManyFamilymembers({
          variables: updateChildVars,
        });
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        toast.error(
          error?.error ??
            "Something went wrong when updating Mother in siblings."
        );
      }
    }
    if (member.Spouse) {
      try {
        await updateManyFamilymembers({
          variables: {
            query: {
              Spouse_in: [{ _id: member._id.toString() }],
            },
            set: {
              Spouse_unset: true,
            },
          },
        });
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        toast.error(
          error?.error ??
            "Something went wrong when updating Mother in siblings."
        );
      }
    }
    try {
      await deleteOneFamilymember({
        variables: {
          query: {
            _id: member._id.toString(),
          },
        },
      });
    } catch (error) {
      setShowLoader(false);
      toast.error(
        error?.error ?? `Something went wrong when updating child in Father`
      );
    }
    setShowLoader(false);
    refetch();
  };
  const confirmCancel = async () => {
    setShowConfirm(false);
    setShowDeleteConfirm(false);
    setShowDetails(false);
  };
  const solutions = [
    {
      name: "Edit Details",
      description: "Get a better understanding of your traffic",
      action: () => {
        setMode("edit");
        setShowConfirm(true);
      },
      icon: PencilIcon,
      enabled:
        member.AddedBy.toString() === user.customData._id
          ? true
          : permissionData?.permission?.can_edit ?? true,
    },
    {
      name: "Add Member",
      description: "Speak directly to your customers",
      action: () => {
        setMode("add");
        setShowConfirm(true);
      },
      icon: UserPlusIcon,
      enabled: permissionData?.permission?.can_add ?? true,
    },
    {
      name: "View Details",
      description: "Get a better understanding of your traffic",
      action: () => {
        setShowDetails(true);
      },
      icon: PencilIcon,
      enabled: true,
    },
    {
      name: "Delete",
      description: "Your customers' data will be safe and secure",
      action: async () => {
        let showAlert = false;
        if (member.Spouse?.length) {
          //if spouse then dont worry about children
          if (member.Spouse.length > 1) {
            showAlert = true;
          } else if (member.Father || member.Mother) {
            showAlert = true;
          }
        } else if (member.Child?.length) {
          if (member.Father || member.Mother) {
            showAlert = true;
          } else {
            if (member.Child.length > 1) {
              const hasSpouse = member.Child.every(
                (child) => getMember(child.toString()).Spouse?.length
              );
              if (!hasSpouse) {
                showAlert = true;
              }
            }
          }
        }
        if (showAlert) {
          return toast.error(
            `Please remove the person which has only one relation`
          );
        }
        setShowDeleteConfirm(true);
      },
      icon: UserMinusIcon,
      enabled:
        member.AddedBy.toString() === user.customData._id
          ? true
          : permissionData?.permission?.can_delete ?? true,
    },
  ];

  if (showLoader) {
    return <Spinner />;
  }
  return (
    <li
      className={classNames(
        "absolute top-0 left-0  cursor-pointer pointer-events-auto",
        windowSize.width > 576 ? "h-20 w-44" : "h-20 w-32"
      )}
      style={{
        left: memberPosition?.left + "px" ?? 0,
        top: memberPosition?.top + "px" ?? 0,
        display: memberPosition ? "block" : "none",
      }}
      title={
        member.Prefix +
        member.FirstName +
        " " +
        (member.Maidenname ? member.Maidenname : member.LastName) +
        " " +
        member.Suffix
      }
      // onClick={(e) => {
      //   makeCenter(e.currentTarget);
      // }}
    >
      {lines?.length > 0 &&
        lines.map((line, index) => (
          <Line
            key={member._id + index}
            verticle={line.verticle}
            length={line.length}
            top={line.top}
            left={line.left}
          />
        ))}

      {showRidgeIcon && (
        <div
          onClick={(e) => {
            showMyTree(e);
          }}
          className="absolute right-0 w-9 h-9 -top-4"
          title={"Show " + member.FirstName + "'s Tree"}
        >
          <img src={ridgeIcon} alt={"Show " + member.FirstName + "'s Tree"} />
        </div>
      )}

      <div
        className={classNames(
          "relative w-full p-2  border border-gray-200 rounded-lg shadow",
          member.Gender === "male"
            ? "bg-blue-300 dark:bg-blue-300"
            : member.Gender === "female"
            ? member.Status !== "unmarried"
              ? "bg-purple-300 dark:bg-purple-300"
              : "bg-pink-300 dark:bg-pink-300"
            : "bg-white dark:bg-white"
        )}
      >
        <div className="absolute right-2 ">
          <Popover className="relative">
            <Popover.Button
              className="inline-flex items-center text-sm font-semibold leading-6 text-gray-900 gap-x-1"
              onClick={() => {
                setShowTip(false);
              }}
            >
              {/* <span>Solutions</span> */}
              <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" />
              {showTip && (
                <div className="absolute p-2 text-white -translate-x-1/2 bg-black rounded-md w-max top-7">
                  Click to Add Member
                  <div className="absolute w-4 h-4 rotate-45 bg-inherit -top-2 left-1/2"></div>
                </div>
              )}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 flex px-2 mt-1 max-w-max">
                <div className="flex-auto max-w-md overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-xl ring-1 ring-gray-900/5">
                  <div className="p-2 gap-y-2">
                    {solutions.map(
                      (item) =>
                        item.enabled && (
                          <div
                            key={item.name}
                            className="relative flex rounded-lg group gap-x-6 hover:bg-gray-50"
                          >
                            <button
                              onClick={item.action}
                              className="w-full font-semibold text-left text-gray-900 whitespace-nowrap"
                            >
                              {item.name}
                            </button>
                          </div>
                        )
                    )}
                  </div>
                  {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
              {callsToAction.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon className="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                  {item.name}
                </a>
              ))}
            </div> */}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
        <div className="flex flex-col items-center overflow-hidden">
          {member.img ? (
            <img
              className="mb-3 rounded-full shadow-lg w-36 h-36"
              src="/docs/images/people/profile-picture-3.jpg"
              alt="Avatar"
            />
          ) : (
            <Avatar member={member} width={36} height={36} />
          )}
          {member.Alive === "false" && (
            <div
              className="absolute top-0 -translate-x-1 left-1/2"
              title={"Late"}
            >
              <FlagIcon
                className="w-5 h-5"
                style={{ fill: "black" }}
                aria-hidden="true"
              />
            </div>
          )}

          <h5 className="mt-1 overflow-hidden text-base font-medium text-gray-900 whitespace-nowrap">
            {member.Prefix} {member.FirstName}{" "}
            {windowSize.width > 576
              ? member.Maidenname
                ? member.Maidenname
                : member.LastName
              : ""}{" "}
            {member.Suffix}
          </h5>
          <h5 className="sr-only">{member._id.toString()}</h5>
          {/* <h5>
            {windowSize.width}px / {windowSize.height}px
          </h5> */}
          {/* <span className="text-sm text-gray-500 dark:text-gray-400">
          Visual Designer
        </span> */}
          {/* <div className="flex mt-4 space-x-3 md:mt-6">
          <a
            href="#"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Add friend
          </a>
          <a
            href="#"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
          >
            Message
          </a>
        </div> */}
        </div>
        {showConfirm && (
          <AddMember
            user={user}
            member={member}
            mode={mode}
            onUpdate={onUpdate}
            tree_id={tree_id}
            onCancel={confirmCancel}
            getMember={getMember}
          />
        )}
        {showDeleteConfirm && (
          <Confirm
            title="Delete Family Member!!!"
            onCancel={confirmCancel}
            msg={`Are you sure, you want to delete ${member.FirstName}?`}
            onConfirm={confirmDelete}
          />
        )}
        {showDetails && (
          <MemberDetails
            member={member}
            title="Delete Family Member!!!"
            onCancel={confirmCancel}
          />
        )}
      </div>
    </li>
  );
}

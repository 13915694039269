import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/user.context";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import logo from "../assets/images/logo.svg";

export default function SignIn() {
  const {
    emailPasswordLogin,
    checkingStatus,
    setCheckingStatus,
    user,
    fetchUser,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const changeHandler = (e) => {
    setformData((prevData) => {
      return { ...prevData, [e.target.id]: e.target.value };
    });
  };
  const signin = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const authenticatedUser = await emailPasswordLogin(email, password);
      if (authenticatedUser) {
        // console.log("user", regUser);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      setCheckingStatus(false);
      toast.error(error?.error ?? "Something went wrong with registration.");
    }
  };
  const loadUser = async () => {
    const signedInUser = await fetchUser();
    if (signedInUser) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    } else {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (checkingStatus) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center sm:pt-0 bg-gray-50">
        <div className="flex ml-4 lg:ml-0">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <span className="sr-only">FMCG</span>
            <img className="w-auto h-16" src={logo} alt="logo" />
          </button>
        </div>

        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <form onSubmit={signin}>
            <div className="mt-4">
              <div className="flex flex-col items-start">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onInput={changeHandler}
                  required
                  placeholder="Email"
                  className="block w-full p-3 mb-4 border rounded border-grey-light"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col items-start">
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onInput={changeHandler}
                  required
                  placeholder="Password"
                  className="block w-full p-3 mb-4 border rounded border-grey-light"
                />
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <div className="flex flex-col">
                <button
                  type="button"
                  className="text-sm text-left text-indigo-600 underline hover:text-indigo-900"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  forgot password?
                </button>
                <button
                  type="button"
                  className="text-sm text-left text-indigo-600 underline hover:text-indigo-900"
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                >
                  Don't have an account?
                </button>
              </div>
              <button
                type="submit"
                className="py-3 my-1 ml-4 text-center text-white bg-indigo-600 rounded px-7 hover:bg-indigo-900 focus:outline-none"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

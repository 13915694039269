import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { UserContext } from "../contexts/user.context";

export default function EmailConfirm() {
  const [showLoader, setShowLoader] = useState(false);
  const { confirmUser } = useContext(UserContext);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");
  // console.log("token", token);
  // console.log("tokenId", tokenId);
  useEffect(() => {
    const confirm = async () => {
      try {
        setShowLoader(true);
        const confirmedUser = await confirmUser(token, tokenId);
        console.log("confirmedUser", confirmedUser);
        toast.success(`Your account has been confimred successfully!!!`);
        navigate("/sign-in");
      } catch (error) {
        console.log(error);
        setShowLoader(false);
        toast.error(
          error?.error ?? "Something went wrong while user confirmation."
        );
      }
    };
    confirm();
  }, []);
  if (!token || !tokenId) {
    toast.error("Please use valid link to reset your password.");
    return;
  }
  if (showLoader) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center sm:pt-0 bg-gray-50">
        <div className="flex ml-4 lg:ml-0">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <span className="sr-only">FMCG</span>
            <img className="w-auto h-16" src={logo} alt="logo" />
          </button>
        </div>

        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg"></div>
      </div>
    </div>
  );
}

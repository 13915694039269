import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../assets/images/logo.svg";
import Spinner from "../components/Spinner";
import { UserContext } from "../contexts/user.context";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useContext(UserContext);
  const [showLoader, setShowLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const tokenId = params.get("tokenId");
  console.log("token", token);
  console.log("tokenId", tokenId);
  if (!token || !tokenId) {
    toast.error("Please use valid link to reset your password.");
    return;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      toast.error("password and confirm password are not same!!");
      return;
    }

    try {
      setShowLoader(true);
      await resetPassword(password, token, tokenId);
      setShowLoader(false);
      toast.success(`Your password has been reset successfully!!!`);
      navigate("/");
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(error?.error ?? "Something went wrong with registration.");
    }
  };
  if (showLoader) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center sm:pt-0 bg-gray-50">
        <div className="flex ml-4 lg:ml-0">
          <button
            onClick={() => {
              navigate("/");
            }}
          >
            <span className="sr-only">Family Tree</span>
            <img className="w-auto h-16" src={logo} alt="logo" />
          </button>
        </div>

        <div className="w-full px-6 py-4 mt-6 overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <form onSubmit={onSubmit}>
            <div className="mt-4">
              <div className="flex flex-col items-start">
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onInput={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                  placeholder="Password"
                  className="block w-full p-3 mb-4 border rounded border-grey-light"
                />
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col items-start">
                <input
                  type="password"
                  name="cpassword"
                  id="cpassword"
                  value={cpassword}
                  onInput={(e) => {
                    setCpassword(e.target.value);
                  }}
                  required
                  placeholder="Confirm Password"
                  className="block w-full p-3 mb-4 border rounded border-grey-light"
                />
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="py-3 my-1 ml-4 text-center text-white bg-indigo-600 rounded px-7 hover:bg-indigo-900 focus:outline-none"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

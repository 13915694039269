import classNames from "classnames";

export default function Line({ verticle, length, top, left }) {
  const lineheight = {
    width: verticle === true ? "2px" : (length || 25) + "px",
    height: verticle === true ? (length || 50) + "px" : "2px",
    top: top,
    left: left,
  };
  return (
    <div
      className={classNames(
        verticle ? " border-l-2 " : "border-t-2",
        "border-solid border-gray-400 absolute"
      )}
      style={lineheight}
    ></div>
  );
}

import { useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  INSERT_ONE_USER,
  UPDATE_ONE_USER,
} from "../contexts/graphql-operations";
import { UserContext } from "../contexts/user.context";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

export default function AccountDetails() {
  const { user, setCheckingStatus, checkingStatus } = useContext(UserContext);
  const navigate = useNavigate();
  const [userExist, setUserExist] = useState(false);
  const [formData, setformData] = useState({
    firstname: "",
    lastname: "",
  });

  const { firstname, lastname } = formData;

  const [insertUser] = useMutation(INSERT_ONE_USER);
  const [updateOneUser] = useMutation(UPDATE_ONE_USER);

  const changeHandler = (e) => {
    setformData((prevData) => {
      return { ...prevData, [e.target.id]: e.target.value };
    });
  };

  const updateUser = async (e) => {
    e.preventDefault();

    try {
      setCheckingStatus(true);
      if (!userExist) {
        const data = await insertUser({
          variables: {
            query: {
              first_name: firstname,
              last_name: lastname,
              user_id: user.id,
              user_email: user._profile.data.email,
              created_at: new Date(),
            },
          },
        });
        setCheckingStatus(false);
        if (data.data.insertOneUser) {
          await user.refreshCustomData();
          toast.success("Your data updated successfully!.");
          navigate("/");
        } else {
          toast.error("Something went wrong with while updating info.");
        }
      } else {
        const updatedUser = await updateOneUser({
          variables: {
            query: {
              _id: user.customData._id,
            },
            set: {
              firstname,
              lastname,
            },
          },
        });
        console.log("updatedUser", updatedUser);
        await user.refreshCustomData();
        setCheckingStatus(false);
        if (updatedUser?.data?.updateOneUser?._id) {
          toast.success("Your data updated successfully!.");
          navigate("/");
        } else {
          toast.error("Something went wrong with while updating info.");
        }
      }
    } catch (error) {
      console.log(error);
      setCheckingStatus(false);
      toast.error(error?.error ?? "Something went wrong with updating info.");
    }
  };

  useEffect(() => {
    console.log("user.customData.mobile", user.customData);
    setformData((prevData) => {
      return {
        ...prevData,
        firstname: user.customData?.firstname ?? "",
        lastname: user.customData?.lastname ?? "",
      };
    });
    if (user.customData.firstname) {
      setUserExist(true);
    }
  }, [user]);

  if (checkingStatus) {
    return <Spinner />;
  }
  // console.log("societies", data);
  // console.log("userData", userData);
  return (
    <div>
      {/* <Header /> */}
      <div className="flex flex-col items-center min-h-screen pt-6 sm:justify-center sm:pt-0 bg-gray-50">
        <div className="w-full px-6 py-4 mt-6 bg-white shadow-md sm:max-w-md sm:rounded-lg">
          <form onSubmit={updateUser}>
            <div>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  onInput={changeHandler}
                  value={firstname}
                  placeholder="First Name"
                  required
                  className="block w-full p-3 mb-4 border rounded border-grey-light"
                />
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start">
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  value={lastname}
                  onInput={changeHandler}
                  required
                  placeholder="Last Name"
                  className="block w-full p-3 mb-4 border rounded border-grey-light"
                />
              </div>
            </div>

            <div className="flex items-center justify-end mt-4">
              <button
                type="submit"
                className="py-3 my-1 ml-4 text-center text-white bg-indigo-600 rounded px-7 hover:bg-indigo-900 focus:outline-none"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

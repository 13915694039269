import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/user.context";
import SignUp from "./pages/SignUp";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SignIn from "./pages/SignIn";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import EmailConfirm from "./pages/EmailConfirm";
import AccountDetails from "./pages/AccountDetails";
import Tree from "./pages/Tree";
import ForgotPassword from "./pages/ForgotPassword";
import PageNotFound from "./pages/PageNotFound";
import ResetPassword from "./pages/ResetPassword";

function App() {
  return (
    <div className="flex flex-col h-screen">
      <Router>
        <UserProvider>
          <Routes>
            <Route path="/tree/:_id" element={<PrivateRoute />}>
              <Route path="/tree/:_id" element={<Tree readOnly />} />
            </Route>
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route path="/account/details" element={<PrivateRoute />}>
              <Route path="/account/details" element={<AccountDetails />} />
            </Route>
            <Route path="/email-confirm" element={<EmailConfirm />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/pagenotfound" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </UserProvider>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;

import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/user.context";
import { useMutation, useQuery } from "@apollo/client";
import {
  FIND_SHARED_TREES,
  FIND_TREES,
  INSERT_ONE_TREE,
} from "../contexts/graphql-operations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import TreeItem from "../components/TreeItem";
import Spinner from "../components/Spinner";
import ConfirmInput from "../components/Dialog/ConfirmInput";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function Home() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [treeOwn, setTreeOwn] = useState([]);
  const [treeParticipated, setTreeParticipated] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [insertOneTree] = useMutation(INSERT_ONE_TREE);
  const {
    loading,
    refetch,
    data: treeData,
  } = useQuery(FIND_TREES, {
    variables: {
      query: {
        is_deleted: false,
        OR: [
          { creator_id: { _id: user.customData._id } },
          { user_id_in: { _id: user.customData._id } },
        ],
      },
    },
  });
  const { data: sharedTreeData } = useQuery(FIND_SHARED_TREES, {
    variables: {
      query: {
        user_email: user.customData.user_email,
      },
    },
  });

  const onCreateTree = async (name) => {
    setShowConfirm(false);
    setShowLoader(true);
    try {
      const data = await insertOneTree({
        variables: {
          query: {
            name: name,
            creator_id: { link: user.customData._id },
            created_at: new Date(),
            is_deleted: false,
            user_id: { link: user.customData._id },
          },
        },
      });
      // console.log("data", data);
      setShowLoader(false);
      refetchTrees();
      // if (data.data?.insertOneTree?._id) {
      //   navigate(`/tree/${data.data?.insertOneTree?._id}`);
      // }
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(error?.error ?? "Something went wrong when creating Tree");
    }
  };

  useEffect(() => {
    console.log("treeData", treeData);
    // console.log("user.customData", user.customData);
    if (treeData?.trees) {
      setTreeOwn(
        treeData.trees.filter(
          (tree) => tree.creator_id._id === user.customData._id
        )
      );
    }
  }, [treeData]);

  useEffect(() => {
    console.log("sharedTreeData", sharedTreeData);
    if (sharedTreeData?.permissions) {
      setTreeParticipated(sharedTreeData.permissions);
    }
  }, [sharedTreeData]);

  const onAdd = (e) => {
    e.preventDefault();
    setShowConfirm(true);
  };
  const confirmCancel = async () => {
    setShowConfirm(false);
  };

  const refetchTrees = async () => {
    setShowLoader(true);
    await refetch();
    setShowLoader(false);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      {showLoader && <Spinner />}
      <Header />
      <div className="pb-8">
        <div className="flex justify-center w-screen">
          <div className="flex flex-col w-full h-full bg-white max-w-7xl ">
            <div className="relative flex flex-col items-center px-4 py-6 overflow-y-auto sm:px-6">
              <h1 className="w-full mb-4 text-3xl font-medium text-left text-gray-900">
                Your Family Trees
              </h1>
              <h3 className="w-full mb-4 text-xl font-medium text-left text-gray-900">
                List of family trees you own / invited
              </h3>
              <button
                type="button"
                onClick={onAdd}
                className="absolute px-1 py-1 my-1 ml-4 text-center text-white bg-indigo-600 rounded-full top-4 right-4 focus:outline-none w-fit sm:hidden"
              >
                <PlusIcon className="w-8 h-8" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={onAdd}
                className="absolute hidden py-3 my-1 ml-4 text-center text-white bg-indigo-600 rounded top-8 right-4 px-7 hover:bg-indigo-900 focus:outline-none w-fit sm:block"
              >
                Create New
              </button>
              <div className="flex flex-col w-full md:flex-row">
                <div className="flex flex-col flex-1">
                  {!treeOwn.length && (
                    <div className="mb-4">
                      <h2 className="hidden sm:block">
                        Please click "Create New" button to create your own
                        Family Tree
                      </h2>
                      <h2 className="sm:hidden">
                        Please click "+" button to create your own Family Tree
                      </h2>
                    </div>
                  )}
                  <div>
                    <ul className="flex flex-wrap">
                      {treeOwn.map((tree) => (
                        <TreeItem
                          user={user}
                          key={tree._id}
                          tree={tree}
                          refetch={refetch}
                        />
                      ))}
                      {treeParticipated.map((tree) => (
                        <TreeItem
                          user={user}
                          key={tree.tree_id._id}
                          tree={tree.tree_id}
                          refetch={refetch}
                          viewOnly
                        />
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div className="flex flex-col flex-1">
                  <h1>Trees You Invited</h1>
                  {!treeParticipated.length && (
                    <div>
                      Please ask you relatives to invite to their family tree
                    </div>
                  )}
                  <div>
                    <ul className="flex flex-wrap">
                      {treeParticipated.map((tree) => (
                        <TreeItem
                          user={user}
                          key={tree.tree_id._id}
                          tree={tree.tree_id}
                          refetch={refetch}
                          viewOnly
                        />
                      ))}
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirm && (
        <ConfirmInput
          title="Add Family Tree"
          onCancel={confirmCancel}
          onConfirm={onCreateTree}
        />
      )}
    </div>
  );
}

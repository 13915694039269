import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import { UserContext } from "../contexts/user.context";
import { useContext, useEffect } from "react";

const PrivateRoute = () => {
  const { user, fetchUser, checkingStatus } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "");
    navigate(redirectTo ? redirectTo : "/");
  };
  const loadUser = async () => {
    if (!user) {
      await fetchUser();
    }
  };

  useEffect(() => {
    loadUser(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (checkingStatus) {
    return <Spinner />;
  }
  // console.log("user", user, user.customData);

  return user ? (
    user.customData?.first_name ? (
      <Outlet />
    ) : location.pathname !== "/account/details" ? (
      <Navigate to="/account/details" />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default PrivateRoute;

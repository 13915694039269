import gql from "graphql-tag";

export const INSERT_ONE_USER = gql`
  mutation InsertUser($query: UserInsertInput!) {
    insertOneUser(data: $query) {
      first_name
      last_name
      user_id
    }
  }
`;

export const UPDATE_ONE_USER = gql`
  mutation updateOneUser($query: UserQueryInput!, $set: UserUpdateInput!) {
    updateOneUser(query: $query, set: $set) {
      _id
    }
  }
`;

export const FIND_FAMILYMEMBER = gql`
  query FindFamilymember($query: FamilymemberQueryInput) {
    familymember(query: $query) {
      Alive
      Bdate
      Child {
        _id
      }
      Mother {
        _id
      }
      Father {
        _id
      }
      Spouse {
        _id
      }
      Ddate
      Email
      FirstName
      Gender
      Image
      LastName
      Maidenname
      Prefix
      Status
      Suffix
      _id
      Tree_id {
        _id
      }
    }
  }
`;

export const FIND_FAMILYMEMBERS = gql`
  query FindFamilymembers(
    $query: FamilymemberQueryInput
    $limit: Int = 100
    $sortBy: FamilymemberSortByInput
  ) {
    familymembers(query: $query, limit: $limit, sortBy: $sortBy) {
      Alive
      Bdate
      Child {
        _id
      }
      Mother {
        _id
      }
      Father {
        _id
      }
      Spouse {
        _id
      }
      Ddate
      Email
      FirstName
      Gender
      Image
      LastName
      Maidenname
      Prefix
      Status
      Suffix
      _id
      Tree_id {
        _id
      }
    }
  }
`;

export const INSERT_ONE_FAMILYMEMBER = gql`
  mutation InsertOneFamilymember($query: FamilymemberInsertInput!) {
    insertOneFamilymember(data: $query) {
      Alive
      Bdate
      Child {
        _id
        Spouse {
          _id
        }
        Mother {
          _id
        }
        Father {
          _id
        }
      }
      Mother {
        _id
        Spouse {
          _id
        }
        Child {
          _id
        }
      }
      Father {
        _id
        Spouse {
          _id
        }
        Child {
          _id
        }
      }
      Spouse {
        _id
        Spouse {
          _id
        }
        Child {
          _id
        }
        Mother {
          _id
        }
        Father {
          _id
        }
      }
      Ddate
      Email
      FirstName
      Gender
      Image
      LastName
      Maidenname
      Prefix
      Status
      Suffix
      _id
      Tree_id {
        _id
      }
    }
  }
`;

export const UPDATE_ONE_FAMILYMEMBER = gql`
  mutation updateOneFamilymember(
    $query: FamilymemberQueryInput!
    $set: FamilymemberUpdateInput!
  ) {
    updateOneFamilymember(query: $query, set: $set) {
      _id
    }
  }
`;

export const UPDATE_MANY_FAMILYMEMBERS = gql`
  mutation updateManyFamilymembers(
    $query: FamilymemberQueryInput!
    $set: FamilymemberUpdateInput!
  ) {
    updateManyFamilymembers(query: $query, set: $set) {
      modifiedCount
    }
  }
`;

export const DELETE_ONE_FAMILYMEMBER = gql`
  mutation deleteOneFamilymember($query: FamilymemberQueryInput!) {
    deleteOneFamilymember(query: $query) {
      _id
    }
  }
`;

export const INSERT_ONE_TREE = gql`
  mutation InsertTree($query: TreeInsertInput!) {
    insertOneTree(data: $query) {
      _id
    }
  }
`;

export const FIND_TREES = gql`
  query FindTrees(
    $query: TreeQueryInput
    $limit: Int = 100
    $sortBy: TreeSortByInput
  ) {
    trees(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      name
      created_at
      creator_id {
        _id
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_ONE_TREE = gql`
  mutation updateOneTree($query: TreeQueryInput!, $set: TreeUpdateInput!) {
    updateOneTree(query: $query, set: $set) {
      _id
    }
  }
`;

export const FIND_PERMISSIONS = gql`
  query FindPermissions(
    $query: PermissionQueryInput
    $limit: Int = 100
    $sortBy: PermissionSortByInput
  ) {
    permissions(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      user_email
      can_edit
      can_delete
      can_add
    }
  }
`;
export const FIND_ONE_PERMISSION = gql`
  query FindPermission($query: PermissionQueryInput) {
    permission(query: $query) {
      _id
      user_email
      can_edit
      can_delete
      can_add
    }
  }
`;

export const FIND_SHARED_TREES = gql`
  query FindPermissions(
    $query: PermissionQueryInput
    $limit: Int = 100
    $sortBy: PermissionSortByInput
  ) {
    permissions(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      tree_id {
        _id
        name
        creator_id {
          _id
          first_name
          last_name
        }
        created_at
      }
      can_edit
      can_delete
      can_add
    }
  }
`;

export const INSERT_ONE_PERMISSION = gql`
  mutation InsertPermission($query: PermissionInsertInput!) {
    insertOnePermission(data: $query) {
      _id
    }
  }
`;

export const UPDATE_ONE_PERMISSION = gql`
  mutation updateOnePermission(
    $query: PermissionQueryInput!
    $set: PermissionUpdateInput!
  ) {
    updateOnePermission(query: $query, set: $set) {
      _id
    }
  }
`;

export const DELETE_ONE_PERMISSION = gql`
  mutation DeletePermission($query: PermissionQueryInput!) {
    deleteOnePermission(query: $query) {
      _id
    }
  }
`;

import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  FIND_ONE_PERMISSION,
  INSERT_ONE_FAMILYMEMBER,
} from "../contexts/graphql-operations";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/user.context";
import { toast } from "react-toastify";
import Header from "../components/Header";
import Member from "../components/tree/Member";
import Spinner from "../components/Spinner";
import dragRight from "../assets/images/icons8-drag-right.gif";
import classNames from "classnames";
import useWindowSize from "../hooks/useWindowSize";
import AddFirstMember from "../components/Dialog/AddFirstMember";
// const bgwidth = 176;
const bgheight = 80;
const gapH = 30;
const gapV = 50;

export default function Tree() {
  const membersPositionData = {};
  const lines = {};
  const ridgeIcon = {};
  const { user } = useContext(UserContext);
  const params = useParams();
  const [initx, setInitx] = useState(0);
  const [inity, setInity] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [curPerson, setCurPerson] = useState(null);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [insertOneFamilymember] = useMutation(INSERT_ONE_FAMILYMEMBER);
  const [memberPosition, setMemberPosition] = useState({});
  const [memberLines, setMemberLines] = useState({});
  const [showRidgeIcon, setShowRidgeIcon] = useState({});
  const [showDragAnim, setShowDragAnim] = useState(true);
  const [animate, setAnimate] = useState(false);
  const windowSize = useWindowSize();
  const [bgwidth, setBgWidth] = useState(window.innerWidth > 576 ? 176 : 128);
  const [familyMembersData, setfamilyMembersData] = useState({});
  const [showAddDialog, setShowAddDialog] = useState(false);
  const { data: permissionData } = useQuery(FIND_ONE_PERMISSION, {
    variables: {
      query: {
        tree_id: { _id: params._id },
        user_email: user.customData.user_email,
      },
    },
  });

  const insertUserAsFamilyMember = async () => {
    setShowLoader(true);
    try {
      const data = await insertOneFamilymember({
        variables: {
          query: {
            FirstName: user.customData.first_name,
            LastName: user.customData.last_name,
            Gender: "male",
            Prefix: "",
            Suffix: "",
            Status: "",
            Email: "",

            Alive: true,
            Image: "",
            Maidenname: "",
            Tree_id: {
              link: params._id,
            },
            AddedBy: {
              link: user.customData._id,
            },
          },
        },
      });
      refetchMembers();
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(error?.error ?? "Something went wrong with add member.");
    }
  };

  const downhandler = (e) => {
    setClicked(true);
    setInitx(e.pageX ?? e.touches[0].pageX);
    setInity(e.pageY ?? e.touches[0].pageY);
  };
  const movehandler = (e) => {
    if (clicked) {
      // console.log(x + e.pageX - this.initx);
      setX((prevX) => prevX + (e.pageX ?? e.touches[0].pageX) - initx);
      setY((prevY) => prevY + (e.pageY ?? e.touches[0].pageY) - inity);
      setInitx(e.pageX ?? e.touches[0].pageX);
      setInity(e.pageY ?? e.touches[0].pageY);
      setShowDragAnim(false);
    }
  };
  const uphandler = (e) => {
    setClicked(false);
  };
  const getMember = (id) => {
    return familyMembersData.familymembers.find(
      (member) => member._id.toString() === id
    );
  };
  const getTopParent = (member) => {
    const id = !member.Father
      ? member.Mother?.toString()
      : member.Father?.toString();
    if (id) {
      const top = getMember(id);
      member = getTopParent(top);
    }
    return member;
  };

  const getSingleChildAncestors = (person, val = 0) => {
    if (person.Mother && person.Father) {
      const personFather = getMember(person.Father.toString());
      if (personFather.Child?.length === 1) {
        return getSingleChildAncestors(personFather, val + 1);
      } else {
        return val;
      }
    } else {
      return val;
    }
  };

  let levelY = 0;
  const leftPos = [0]; // to save the latest left Position level wise
  const updatePosition = (person) => {
    membersPositionData[person._id.toString()] = {};
    lines[person._id.toString()] = [];
    // if children then start drawing each child's tree
    if (person.Child?.length) {
      // level up
      levelY++;
      person.Child.forEach((child) => {
        updatePosition(getMember(child.toString()));
      });
      // level down
      levelY--;
      // children loop completed so finding positin for parents
      const firstChildId = person.Child[0].toString();
      const firstChildPos = membersPositionData[firstChildId];
      const lastChildId = person.Child[person.Child.length - 1].toString();
      const lastChildPos = membersPositionData[lastChildId];
      // find the center point of the children
      let parentLeft = person.Spouse?.length
        ? (lastChildPos.left - firstChildPos.left) / 2 - (bgwidth + gapH) / 2
        : (lastChildPos.left - firstChildPos.left) / 2;
      // adding first child left to the center point
      parentLeft += firstChildPos.left;
      leftPos[levelY] = parentLeft;
      membersPositionData[person._id.toString()].left = leftPos[levelY];
      leftPos[levelY] += bgwidth + gapH;
      // adding line between children
      if (person.Child.length > 1) {
        lines[firstChildId].push({
          verticle: false,
          length: lastChildPos.left - firstChildPos.left,
          top: -gapV / 2,
          left: bgwidth / 2,
        });
      }
      if (!person.Spouse || person.Spouse?.length === 0) {
        lines[person._id.toString()].push({
          verticle: true,
          length: gapV / 2,
          top: bgheight,
          left: bgwidth / 2,
        });
      }
    } else {
      if (isNaN(leftPos[levelY])) leftPos[levelY] = 0;
      if (
        !isNaN(leftPos[levelY - 1]) &&
        leftPos[levelY - 1] >= leftPos[levelY]
      ) {
        // getting max leftPos from all earlier level
        let cnt = levelY,
          maxLeftPos = 0;
        maxLeftPos = Math.max(maxLeftPos, leftPos[levelY]);
        while (cnt--) {
          maxLeftPos = Math.max(maxLeftPos, leftPos[cnt] ?? 0);
        }
        // console.log("maxLeftPos", maxLeftPos);
        // console.log(Math.max(leftPos[levelY], leftPos[levelY - 1]));
        const mult = getSingleChildAncestors(person);
        console.log("mult", mult);
        if (mult) {
          leftPos[levelY] = maxLeftPos + ((bgwidth + gapH) * mult) / 2;
        } else {
          leftPos[levelY] = maxLeftPos;
        }
      }
      membersPositionData[person._id.toString()].left = leftPos[levelY];
      leftPos[levelY] += bgwidth + gapH;
    }
    // drawing top line
    if (levelY) {
      // no need to add top line for first level which 0
      lines[person._id.toString()].push({
        verticle: true,
        length: gapV / 2,
        top: -gapV / 2,
        left: bgwidth / 2,
      });
    }
    membersPositionData[person._id.toString()].top = levelY * (bgheight + gapV);
    console.log(
      person.FirstName,
      levelY,
      membersPositionData[person._id.toString()].left
    );
    if (person.Spouse?.length) {
      let spouseLeft =
        membersPositionData[person._id.toString()].left + bgwidth + gapH;
      person.Spouse.forEach((spouse, index) => {
        // drawing spouse connect line
        const personSpouse = getMember(spouse.toString());
        lines[person._id.toString()].push({
          verticle: false,
          length:
            spouseLeft -
            membersPositionData[person._id.toString()].left -
            bgwidth,
          top: bgheight / 2 + index * 5,
          left: bgwidth,
        });
        // drawing child connect line
        if (person.Child?.length) {
          lines[person._id.toString()].push({
            verticle: true,
            length: (gapV + bgheight) / 2,
            top: bgheight / 2,
            left: bgwidth + gapH / 2,
          });
        }
        membersPositionData[personSpouse._id.toString()] = {};
        membersPositionData[personSpouse._id.toString()].left = spouseLeft;
        membersPositionData[personSpouse._id.toString()].top =
          levelY * (bgheight + gapV);
        console.log(
          personSpouse.FirstName,
          levelY,
          membersPositionData[personSpouse._id.toString()].left
        );
        spouseLeft += bgwidth + gapH;
        //taking common children in array
        let commonchildary = [];
        if (personSpouse.Child?.length) {
          commonchildary = personSpouse.Child.filter((child) =>
            person.Child.some(
              (personChild) => personChild.toString() === child.toString()
            )
          );
        }
        //showing tree icon if family member are there
        if (
          personSpouse.Father ||
          personSpouse.Mother ||
          personSpouse.Spouse?.length > 1 ||
          personSpouse.Child?.length > commonchildary.length
        ) {
          ridgeIcon[personSpouse._id.toString()] = true;
        }
      });
      leftPos[levelY] = spouseLeft;
    }
  };

  useEffect(() => {
    if (familyMembersData.familymembers) {
      console.log("familyMembersData", familyMembersData);
      if (familyMembersData.familymembers.length) {
        //set cur person
        if (!curPerson) setCurPerson(familyMembersData.familymembers[0]);
        else {
          const curPersonClone = getMember(curPerson._id.toString());
          //if curperson is removed then take first family member
          if (curPersonClone)
            setCurPerson({ ...getMember(curPerson._id.toString()) });
          else setCurPerson(familyMembersData.familymembers[0]);
        }
      } else {
        // insertUserAsFamilyMember();
      }
    }
  }, [familyMembersData]);

  const getData = async () => {
    const data = await user.functions.getTreeMembers({
      tree_id: params._id,
    });
    console.log("data", data.result);
    // console.log(data.result[0]._id.toString());
    // console.log(data.result[0].Tree_id.toString());
    setfamilyMembersData({ familymembers: data.result });
    setLoading(false);
  };

  useEffect(() => {
    if (!familyMembersData.familymembers?.length || !loading) {
      setLoading(true);
      console.log("hi");
      getData();
    }
  }, []);

  useEffect(() => {
    if (curPerson) {
      //get top parent
      const topParent = getTopParent(
        curPerson ?? familyMembersData.familymembers[0]
      );
      console.log("topParent", topParent);
      updatePosition(topParent);
      setMemberPosition({ ...membersPositionData });
      setMemberLines({ ...lines });
      setShowRidgeIcon({ ...ridgeIcon });
      // console.log("membersPositionData", membersPositionData);
    }
  }, [curPerson]);

  useEffect(() => {
    if (windowSize && familyMembersData.familymembers?.length && curPerson) {
      setBgWidth(windowSize.width > 576 ? 176 : 128);
      setCurPerson({ ...getMember(curPerson._id.toString()) });
    }
  }, [windowSize]);

  const makeCenter = (element) => {
    const memberClientRect = element.getBoundingClientRect();
    const memberCenterX = memberClientRect.left + bgwidth / 2;
    const memberCenterY = memberClientRect.top + 200 + bgheight / 2;
    setAnimate(true);
    setX((prevX) => prevX - memberCenterX + window.innerWidth / 2);
    setY((prevY) => prevY - memberCenterY + window.innerHeight / 2);
    setTimeout(setAnimate, 500, false);
  };

  const refetchMembers = async () => {
    setShowLoader(true);
    await getData();
    setShowLoader(false);
  };

  const onAdd = () => {
    setShowAddDialog(true);
  };

  const onUpdate = () => {
    setShowAddDialog(false);
    refetchMembers();
  };

  const confirmCancel = async () => {
    setShowAddDialog(false);
  };

  if (loading) {
    return <Spinner />;
  }

  const treestyle = {
    top: y,
    left: x,
    width: x < 0 ? window.innerWidth + Math.abs(x) : window.innerWidth,
    height: y < 0 ? window.innerHeight + Math.abs(y) : window.innerHeight,
  };

  return (
    <div>
      {showLoader && <Spinner />}
      <Header />
      <div>
        <div>
          <div className=" text-center relative w-full h-[calc(100vh-65px)] overflow-hidden bg-white">
            {familyMembersData?.familymembers?.length ? (
              <div
                className={classNames(
                  "absolute flex items-start  cursor-move select-none justify-left",
                  animate && "transition-[top,left] ease-in-out delay-350 "
                )}
                style={treestyle}
                onMouseDown={downhandler}
                onTouchStart={downhandler}
                onMouseMove={movehandler}
                onTouchMove={movehandler}
                onMouseUp={uphandler}
                onTouchEnd={uphandler}
                onMouseLeave={uphandler}
              >
                <ul className="absolute">
                  {familyMembersData.familymembers.map((member) => {
                    return (
                      <Member
                        key={member._id.toString()}
                        member={member}
                        user={user}
                        refetch={refetchMembers}
                        tree_id={params._id}
                        memberPosition={memberPosition[member._id.toString()]}
                        lines={memberLines[member._id.toString()]}
                        showRidgeIcon={showRidgeIcon[member._id.toString()]}
                        getMember={getMember}
                        permissionData={permissionData}
                        showTree={(element) => {
                          setMemberPosition({});
                          setMemberLines({});
                          setShowRidgeIcon({});
                          setCurPerson(member);
                          setTimeout(makeCenter, 250, element);
                        }}
                        makeCenter={makeCenter}
                        showTooltip={
                          familyMembersData.familymembers.length === 1
                        }
                      />
                    );
                  })}
                </ul>
                {showDragAnim &&
                  familyMembersData.familymembers?.length === 2 && (
                    <img
                      className="fixed w-auto h-16 top-1/3 left-1/3"
                      src={dragRight}
                      alt="dragRight"
                    />
                  )}
              </div>
            ) : (
              <button
                type="button"
                onClick={onAdd}
                className="py-3 my-4 ml-4 text-center text-white bg-indigo-600 rounded px-7 hover:bg-indigo-900 focus:outline-none w-fit"
              >
                Add First Family Memeber
              </button>
            )}
          </div>
        </div>
      </div>
      {showAddDialog && (
        <AddFirstMember
          user={user}
          mode={"add"}
          onUpdate={onUpdate}
          tree_id={params._id}
          onCancel={confirmCancel}
          getMember={getMember}
        />
      )}
    </div>
  );
}

import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Confirm from "./Dialog/Confirm";
import Spinner from "./Spinner";
import { useMutation } from "@apollo/client";
import { UPDATE_ONE_TREE } from "../contexts/graphql-operations";
import { toast } from "react-toastify";
import { PencilIcon, ShareIcon, TrashIcon } from "@heroicons/react/24/outline";
import ShareDialog from "./share/ShareDialog";
const classNames = require("classnames");

function TreeItem({ user, tree, refetch, viewOnly = false }) {
  const { _id, created_at, creator_id, name } = tree;
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [updateOneTree] = useMutation(UPDATE_ONE_TREE);
  const onModify = (e) => {
    e.preventDefault();
    navigate(`/tree/${_id}`);
  };

  const onDelete = (e) => {
    e.preventDefault();
    setShowConfirm(true);
  };

  const onShare = (e) => {
    e.preventDefault();
    setShowSharePopup(true);
  };

  const shareCancel = async () => {
    setShowSharePopup(false);
  };

  const confirmShare = async () => {
    setShowSharePopup(false);
  };

  const confirmCancel = async () => {
    setShowConfirm(false);
  };

  const confirmDelete = async () => {
    setShowConfirm(false);
    setShowLoader(true);
    try {
      const data = await updateOneTree({
        variables: {
          query: {
            _id: _id,
          },
          set: {
            is_deleted: true,
            deleted_on: new Date(),
          },
        },
      });
      console.log("data", data);
      await refetch();
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(error?.error ?? "Something went wrong when deleting Tree");
    }
  };

  useEffect(() => {}, [tree]);

  if (showLoader) {
    return <Spinner />;
  }

  return (
    <li
      className={classNames(
        "w-full p-1  md:w-1/3 xl:w-auto ",
        creator_id._id === user.customData._id ? "bg-blue-50" : "bg-pink-50"
      )}
    >
      <div className="relative flex p-3 border border-gray-300 rounded-lg shadow-md">
        <div className="flex flex-col flex-1 gap-1 ml-4">
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>{name}</h3>
          </div>
          <div className="flex items-end justify-between flex-1 ">
            <p className="text-gray-900">
              Created by:
              <span className="ml-2">
                {creator_id._id === user.customData._id
                  ? "You"
                  : creator_id.first_name + " " + creator_id.last_name}
              </span>
            </p>
          </div>
          <div className="flex items-end justify-between flex-1 mb-4 ">
            <p className="text-gray-900">
              Created on:
              <span className="ml-2">
                {moment(created_at).format("DD-MMM-YYYY")}
              </span>
            </p>
          </div>

          <div className="flex items-end justify-end flex-1 ">
            <div className="flex gap-2">
              <button
                type="button"
                className={classNames(
                  "px-2 py-2 text-center text-white bg-indigo-600 rounded hover:bg-indigo-900 focus:outline-none",
                  viewOnly ? "disabled:opacity-25" : ""
                )}
                onClick={onModify}
              >
                <PencilIcon className="w-5 h-5" aria-hidden="true" />
              </button>
              {!viewOnly && (
                <button
                  type="button"
                  className={classNames(
                    "px-2 py-2 text-center text-white bg-indigo-600 rounded hover:bg-indigo-900 focus:outline-none",
                    viewOnly ? "disabled:opacity-25" : ""
                  )}
                  onClick={onShare}
                >
                  <ShareIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              )}
              {!viewOnly && (
                <button
                  type="button"
                  className={classNames(
                    "px-2 py-2 text-center text-white bg-indigo-600 rounded hover:bg-indigo-900 focus:outline-none",
                    viewOnly ? "disabled:opacity-25" : ""
                  )}
                  onClick={onDelete}
                >
                  <TrashIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              )}
            </div>
          </div>
        </div>

        {creator_id._id === user.customData._id ? (
          <span class="absolute right-0 bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
            Own
          </span>
        ) : (
          <span class="absolute right-0 bg-pink-100 text-pink-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">
            Invited
          </span>
        )}
      </div>
      {showConfirm && (
        <Confirm
          title="Delete Family Tree!!!"
          onCancel={confirmCancel}
          msg={`Are you sure, you want to delete ${name}?`}
          onConfirm={confirmDelete}
        />
      )}
      {showSharePopup && (
        <ShareDialog
          user={user}
          tree={tree}
          title="Share your tree with family"
          onCancel={shareCancel}
          msg={`Are you sure, you want to delete ${name}?`}
          onConfirm={confirmShare}
        />
      )}
    </li>
  );
}

export default TreeItem;

import { useEffect } from "react";

const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#686868",
  "#7f8c8d",
];
export default function Avatar({ member, width = 30, height = 30 }) {
  const initials =
      member.FirstName.charAt(0).toUpperCase() +
      (member.Maidenname ? member.Maidenname : member.LastName)
        .charAt(0)
        .toUpperCase(),
    charIndex = initials.charCodeAt(0) - 65,
    colorIndex = charIndex % 19,
    style = {
      backgroundColor: colors[colorIndex],
      width: width,
      height: height,
      font: width / 2 + "px Arial",
      color: "#FFF",
      textAlign: "center",
      lineHeight: height + "px",
      borderRadius: "50%",
    };

  useEffect(() => {});

  return <div style={style}>{initials}</div>;
}

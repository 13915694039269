import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { INSERT_ONE_FAMILYMEMBER } from "../../contexts/graphql-operations";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import moment from "moment/moment";

export default function AddFirstMember({ tree_id, user, onCancel, onUpdate }) {
  const [insertOneFamilymember] = useMutation(INSERT_ONE_FAMILYMEMBER);
  const [showLoader, setShowLoader] = useState(false);

  function closeModal() {
    onCancel();
  }

  const [formData, setformData] = useState({
    FirstName: "",
    LastName: "",
    Gender: "",
    Prefix: "",
    Suffix: "",
    Status: "",
    Email: "",
    Bdate: "",
    Ddate: "",
    Alive: true,
    Maidenname: "",
  });

  const {
    FirstName,
    LastName,
    Gender,
    Prefix,
    Suffix,
    Status,
    Email,
    Bdate,
    Ddate,
    Alive,
    Maidenname,
  } = formData;

  const changeHandler = (e) => {
    setformData((prevData) => {
      return {
        ...prevData,
        [e.target.id]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      };
    });
  };

  const setGender = (e) => {
    setformData((prevData) => {
      return { ...prevData, Gender: e.target.value };
    });
  };

  const addMember = async (e) => {
    e.preventDefault();
    if (!Gender) return toast.error("Please select Gender.");
    try {
      setShowLoader(true);
      const data = await insertOneFamilymember({
        variables: {
          query: {
            FirstName,
            LastName,
            Gender,
            Prefix,
            Suffix,
            Status,
            Email,
            Alive,
            Bdate: new Date(Bdate),
            Ddate: new Date(Ddate),
            Image: "",
            Maidenname,
            Tree_id: {
              link: tree_id,
            },
            AddedBy: {
              link: user.customData._id,
            },
          },
        },
      });
      onUpdate();
      setShowLoader(false);
    } catch (error) {
      console.log(error);
      setShowLoader(false);
      toast.error(
        error?.error ?? "Something went wrong with add / update member."
      );
    }
  };

  if (showLoader) {
    return <Spinner />;
  }

  return (
    <>
      <Transition appear show as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            className="fixed inset-0 overflow-y-auto"
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Add Family Member
                  </Dialog.Title>
                  <form onSubmit={addMember}>
                    <div className="mt-4">
                      <div className="flex flex-row items-start">
                        <div className="flex items-center mr-4">
                          <input
                            checked={Gender === "male"}
                            id="inline-radio"
                            type="radio"
                            value="male"
                            name="gender"
                            onChange={setGender}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          />
                          <label
                            htmlFor="inline-radio"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Male
                          </label>
                        </div>
                        <div className="flex items-center mr-4">
                          <input
                            checked={Gender === "female"}
                            id="inline-2-radio"
                            type="radio"
                            value="female"
                            name="gender"
                            onChange={setGender}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          />
                          <label
                            htmlFor="inline-2-radio"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Female
                          </label>
                        </div>
                        <div className="flex items-center mr-4">
                          <input
                            checked={Gender === "unknown"}
                            id="inline-checked-radio"
                            type="radio"
                            value="unknown"
                            name="gender"
                            onChange={setGender}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                          />
                          <label
                            htmlFor="inline-checked-radio"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Unknown
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-x-4 md:grid-cols-2 ">
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="Prefix"
                            name="Prefix"
                            onChange={changeHandler}
                            value={Prefix}
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            <option value="">Choose prefix</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Prof.">Prof.</option>
                            <option value="Rev">Rev</option>
                            <option value="Sir">Sir</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="Suffix"
                            name="Suffix"
                            onChange={changeHandler}
                            value={Suffix}
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            <option value="">Choose suffix</option>
                            <option value="I">I</option>
                            <option value="II">II</option>
                            <option value="III">III</option>
                            <option value="Sr">Sr</option>
                            <option value="Jr">Jr</option>
                            <option value="Esq.">Esq.</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4 ">
                        <div className="flex flex-col items-start">
                          <input
                            type="text"
                            name="FirstName"
                            id="FirstName"
                            value={FirstName}
                            onInput={changeHandler}
                            required
                            placeholder="First Name"
                            className="block w-full p-1.5 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                      <div className="mt-4 ">
                        <div className="flex flex-col items-start">
                          <input
                            type="text"
                            name="LastName"
                            id="LastName"
                            value={LastName}
                            onInput={changeHandler}
                            required
                            placeholder="Last Name"
                            className="block w-full p-1.5 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="flex flex-col items-start">
                          <select
                            id="Status"
                            name="Status"
                            onChange={changeHandler}
                            value={Status}
                            required
                            className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                          >
                            <option>Choose status</option>
                            <option value="married">Married</option>
                            <option value="unmarried">Unmarried</option>
                            <option value="divorced">Divorced</option>
                            <option value="widow">Widow / Widower</option>
                            <option value="separated">Separated</option>
                            <option value="engaged">Engaged</option>
                            <option value="partners">Partners</option>
                            <option value="friends">Friends</option>
                            <option value="unknown">Unknown</option>
                            <option value="none">None</option>
                          </select>
                        </div>
                      </div>

                      {Gender !== "male" && Status !== "unmarried" && (
                        <div className="mt-4 ">
                          <div className="flex flex-col items-start">
                            <input
                              type="text"
                              name="Maidenname"
                              id="Maidenname"
                              value={Maidenname}
                              onInput={changeHandler}
                              placeholder="Maiden Name"
                              className="block w-full p-1.5 border rounded border-grey-light"
                            />
                          </div>
                        </div>
                      )}
                      <div className="mt-4 ">
                        <div className="flex flex-col items-start">
                          <input
                            type="email"
                            name="Email"
                            id="Email"
                            value={Email}
                            onInput={changeHandler}
                            placeholder="Email address"
                            className="block w-full p-1.5 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mb-4 gap-x-4">
                      <div className="mt-4 ">
                        <div className="flex flex-row items-start">
                          <h3 className="mr-4 font-semibold leading-8 text-gray-900 ">
                            Birthdate:
                          </h3>
                          <input
                            type="date"
                            name="Bdate"
                            id="Bdate"
                            value={moment(Bdate).format("YYYY-MM-DD")}
                            max={moment().format("YYYY-MM-DD")}
                            onInput={changeHandler}
                            placeholder="Maiden Name"
                            className="block w-full p-1 border rounded border-grey-light"
                          />
                        </div>
                      </div>
                      <div className="mt-4 ">
                        <div className="flex items-center">
                          <input
                            checked={Alive}
                            id="Alive"
                            name="Alive"
                            type="checkbox"
                            onChange={changeHandler}
                            className="w-4 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded "
                          />
                          <label
                            htmlFor="Alive"
                            className="ml-2 text-sm font-medium text-gray-900 "
                          >
                            Alive
                          </label>
                        </div>
                      </div>
                      {!Alive && (
                        <div className="mt-4 ">
                          <div className="flex flex-row items-start">
                            <h3 className="mr-4 font-semibold leading-8 text-gray-900 whitespace-nowrap">
                              Date of Death:
                            </h3>
                            <input
                              type="date"
                              name="Ddate"
                              id="Ddate"
                              value={moment(Ddate).format("YYYY-MM-DD")}
                              max={moment().format("YYYY-MM-DD")}
                              onInput={changeHandler}
                              placeholder="Maiden Name"
                              className="block w-full p-1 border rounded border-grey-light"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="w-full py-3 my-1 text-center text-white bg-indigo-600 rounded px-7 hover:bg-indigo-900 focus:outline-none"
                    >
                      Add Member
                    </button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
